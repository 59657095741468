import "core-js/modules/es.array.push.js";
import "core-js/modules/es.error.cause.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Dialog, Toast, CountDown, Button, Popup, Rate, Field, Overlay, Icon } from 'vant';
import { Getter, Action } from 'vuex-class';
import { Letters } from '@/constants/common';
import { gupaoEduAuth, getPlatform, escapeHtml } from '@/utils/util';
import { LOGINTOKEN } from '@/constants/common';
import cookies from 'js-cookie';
import qs from 'query-string';
import store from '@/store';
let countdown = 0; // 倒计时的总数
let myInter;
let QuestionVue = class QuestionVue extends Vue {
  constructor() {
    super(...arguments);
    this.idx = 1; // 当前题目索引
    // 显示答题卡
    this.showCard = false;
    // 图片预览
    this.preview = {
      show: false,
      path: ''
    };
    // 答案索引
    this.letter = Letters;
    // 单多选作答记录（每道题对应的答案）
    this.answerRecord = {};
    // 问答题的作答内容
    this.message = '';
    // 提交里的已答题ids组
    this.ids = [];
    // 是否继续考试
    this.isContinue = false;
    // 考次ID
    this.examineId = 0;
    this.ix = getPlatform().isPhoneX;
    // 加载中
    this.loading = false;
    // 每一个题目提交的加载中
    this.onceLoading = false;
    this.usetime = 0;
    // 当前显示数据
    this.curr = {};
  }
  // 获取时
  get useTimeHour() {
    return Math.floor(this.usetime / 1000 / 60 / 60);
  }
  get useTimeMinutes() {
    return Math.floor(this.usetime / 1000 / 60 % 60);
  }
  get useTimeSecond() {
    return Math.floor(this.usetime / 1000 % 60);
  }
  get isPC() {
    return getPlatform().isPC;
  }
  // v-html特殊字符转义
  get escapeHtml() {
    return s => escapeHtml(s);
  }
  // type：1 错题集练习。0：默认逻辑
  get type() {
    const {
      type
    } = this.$route.params;
    return type ? parseInt(type) : 0;
  }
  // 返回当前题目类型对应的classname
  get questionTypeClassName() {
    return ['radio', 'check', 'qa'][this.curr.quizChoiceType - 1] || '';
  }
  // 返回当前题目类型的标签名
  get questionTagText() {
    return ['单选题', '多选题', '简答题'][this.curr.quizChoiceType - 1] || '';
  }
  // 是否为多选题
  get isMultiple() {
    return this.curr.quizChoiceType == 2;
  }
  // 当前题目是否需要提交
  get needOnceCommit() {
    const item = this.data.quizInfoList[this.idx - 1];
    return item.isAnswer ? 0 : 1;
  }
  // 是否为练习模式（练习模式每回答一题都会给出结果）
  get isTest() {
    if (isNaN(this.data.countdown)) return true;
    return this.data.countdown == 0 ? true : false;
  }
  async created() {
    const {
      path,
      query
    } = this.$route;
    if (query.tk) {
      cookies.set(LOGINTOKEN, decodeURIComponent(query.tk.toString()));
      delete query.tk;
      this.$router.replace(`${path}?${qs.stringify(query)}`);
    }
    // 检查是否有权限
    await this.checkAuth();
    // 检查是否有进行中的考卷
    if (this.type != 1) {
      await this.checkExaming();
    }
    // 检查回答
    this.checkActivityAnswer();
    const loading = this.showLoading();
    // 请求获取问题列表
    const id = this.$route.params.id;
    const r = this.type == 1 ? await this.getErrorRecordListApi(id) : await this.getQuestionList(id);
    loading.close();
    // 倒计时总数（性能优化）
    countdown = this.data.countdown;
    if (!countdown) {
      this.usetime = 0;
      this.continueTiming();
    }
    if (r.code === 0) {
      this.switchCurrentQuestion();
    } else {
      var _r$msg;
      // 登陆失效拦截
      if (r.code === 1001 || ((_r$msg = r.msg) === null || _r$msg === void 0 ? void 0 : _r$msg.indexOf('未登录')) > -1) {
        gupaoEduAuth(this.$route.fullPath);
      } else {
        this.$toast({
          message: r.msg || '数据请求失败'
        });
      }
    }
  }
  // 检查权限
  async checkAuth() {
    const tk = cookies.get(LOGINTOKEN);
    if (tk) {
      try {
        // 考卷ID
        const id = this.$route.params.id;
        await store.dispatch('Paper/getPaperDetail', id);
        const paper = store.state.Paper;
        if (!paper.data.isOwn) {
          await Dialog.alert({
            title: '温馨提示',
            message: `您暂无此考卷的作答权限`
          });
          this.$router.push('/');
          return Promise.reject('无此考卷作答权限');
        } else {
          return Promise.resolve('successed');
        }
      } catch {
        // 他妈的，token失效没有专属code和提示。直接报错。那就这么粗暴的写了算了吧
        gupaoEduAuth(this.$route.fullPath);
      }
    } else {
      gupaoEduAuth(this.$route.fullPath);
    }
  }
  // 检查是否有正在进行的考卷
  async checkExaming() {
    // 考卷ID
    const id = this.$route.params.id;
    try {
      this.loading = true;
      // 检查是否存在考场
      const checkRes = await this.checkExamingAction(id);
      if (checkRes.code == 0) {
        // 存在进行中的考试
        if (checkRes.data.examineId && checkRes.data.answerVOList.length > 0) {
          Dialog.confirm({
            title: '提示',
            message: '当前试卷您存在一场进行中的考试，是否继续?',
            confirmButtonText: '继续',
            cancelButtonText: '重新开始'
          }).then(async () => {
            // 继续
            this.examineId = checkRes.data.examineId;
            // 整理正确答案
            const answerRecord = {};
            checkRes.data.answerVOList.forEach(answerItem => {
              const item = this.data.quizInfoList.find(qItem => qItem.id == answerItem.quizId);
              if (item) {
                var _answerItem$answer, _answerItem$question;
                // 计算出是否回答正确
                answerItem.isRight = ((_answerItem$answer = answerItem.answer) === null || _answerItem$answer === void 0 ? void 0 : _answerItem$answer.replace(/,/g, "")) == ((_answerItem$question = answerItem.question) === null || _answerItem$question === void 0 ? void 0 : _answerItem$question.replace(/,/g, ""));
                // 标记为已答，用于排序
                item.isAnswer = 1;
                item.answerInfo = answerItem;
                // 单选题
                if (item.quizChoiceType == 1) {
                  answerRecord[item.id] = [answerItem.question];
                }
                // 多选题
                else if (item.quizChoiceType == 2) {
                  answerRecord[item.id] = item.optionList.map(optionItem => {
                    return answerItem.question.indexOf(optionItem.op) != -1 ? optionItem.op : '';
                  });
                }
                // 简答题
                else if (item.quizChoiceType == 3) {
                  answerRecord[item.id] = answerItem.question;
                }
              }
            });
            // 排序
            this.data.quizInfoList = this.data.quizInfoList.sort((a, b) => {
              return a.isAnswer - b.isAnswer;
            });
            // 计算出已答题位置当前
            const index = this.data.quizInfoList.filter(item => item.isAnswer).length;
            this.idx = index >= this.data.quizInfoList.length ? index : index + 1;
            this.answerRecord = answerRecord;
            this.switchCurrentQuestion();
          }).catch(async () => {
            // 先结束、再开始新一轮考试
            const params = {
              id: checkRes.data.examineId,
              packageId: id
            };
            const res = await this.againAnswerAction(params); // 考次ID
            if (res.code == 0) {
              this.examineId = res.data;
            } else {
              Toast.fail(checkRes.msg || '加载错误');
            }
          });
        }
        // 存在已开始但是有未答题的。直接标识为已完成
        // else if(checkRes.data.examineId && checkRes.data.answerVOList.length == 0){
        //   // 先结束、再开始新一轮考试
        //   await this.endAnswerAction(checkRes.data.examineId)// 考次ID
        //   const res = await this.starAnswerAction(id)
        //   console.log('1r',res)
        //   this.examineId = res.data
        // }
        // 开始答题
        else if (checkRes.data.examineId) {
          this.examineId = checkRes.data.examineId;
        } else {
          const res = await this.starAnswerAction(id);
          this.examineId = res.data;
        }
      } else {
        Toast.fail(checkRes.msg || '加载错误');
      }
    } catch (e) {
      setTimeout(() => {
        const message = e.msg || '加载错误';
        const regex = /最多考试\d+次/;
        Dialog({
          title: '提示',
          message
        }).then(() => {
          if (message && regex.test(message)) {
            this.$router.replace('/');
          }
        });
      }, 100);
    } finally {
      this.loading = false;
    }
  }
  // 查询业活动作答情况
  async checkActivityAnswer() {
    const id = this.$route.query.acti;
    if (id) {
      const loading = this.showLoading();
      const r = await this.getActivityInfoById(~~id);
      loading.close();
      if (r.code === 0) {
        if (r.data.answerNums >= r.data.joinNums) {
          // 表示已经达到作答
          this.$router.forward();
        }
      }
    }
  }
  showLoading() {
    return this.$toast.loading({
      forbidClick: true,
      duration: 0
    });
  }
  // 弹层答题卡试题作答状态
  answerStatus(item) {
    const curr = this.curr;
    const answer = this.answerRecord;
    if (item.id === curr.id) {
      // 进行中
      return 'cur';
    }
    if (answer[item.id]) {
      // 已答题
      return 'on';
    }
    return '';
  }
  // 倒计时变化事件
  onCountdownChange(data) {
    this.usetime = countdown - data.total;
  }
  // 倒计时结束
  onCountdownFinish() {
    Dialog.confirm({
      title: '考试时间已到',
      message: '考试时间已消耗完，请提交考卷或继续考试',
      confirmButtonText: '提交考卷',
      cancelButtonText: '继续考试'
    }).then(() => this.submitAnswer()).catch(() => this.continueTiming());
  }
  // 继续考试时的计时
  continueTiming() {
    this.isContinue = true;
    clearInterval(myInter);
    myInter = setInterval(() => this.usetime += 1000, 1000);
  }
  // 切换显示题目
  switchCurrentQuestion() {
    const list = this.data.quizInfoList;
    const curr = list[this.idx - 1];
    const {
      optionList = [],
      quizChoiceType,
      answerInfo: {
        question = ''
      } = {}
    } = curr || {};
    const len = optionList.length; // 选项的长度
    const radio = quizChoiceType === 1; // 是否是单选
    const record = this.answerRecord[curr.id]; // 当前试题的已选项
    if (quizChoiceType !== 1) {
      this.message = question || '';
    }
    this.curr = {
      ...curr,
      checked: [...new Array(len)].map((n, i) => {
        // 多选题
        if (quizChoiceType == 2 && record) return record[i] ? true : false;
        // 不是单选题
        if (!radio) return !!record;
        if (record) return radio ? record.includes(Letters[i]) : !!record[i];
        return false;
      })
    };
  }
  // 点击预览图片
  onTapPreview(path) {
    this.preview = {
      show: true,
      path
    };
  }
  onTapPrev() {
    --this.idx < 1 && (this.idx = 1);
    this.switchCurrentQuestion();
  }
  async onTapNext() {
    const {
      quizType
    } = this.curr;
    if (quizType !== 1 && this.message === '') {
      Toast('请填写答案！');
      return;
    }
    try {
      this.onceLoading = true;
      const res = await this.commitOnceAnswer();
      // 只有遇到 isNext 标识了才进入下一题
      if (!res.isNext) return;
      this.message = '';
      if (++this.idx > this.lens) {
        this.idx = this.lens;
      }
      this.switchCurrentQuestion();
    } catch (e) {
      Toast({
        message: e.message || e.msg || '服务器异常',
        position: 'bottom'
      });
    } finally {
      this.onceLoading = false;
    }
  }
  onTapJump(index) {
    this.idx = index + 1;
    this.switchCurrentQuestion();
    this.showCard = false;
  }
  // 点击下一题时，提交当前题目答案到服务器，用于历史定位
  async commitOnceAnswer() {
    var _item$answerInfo, _item$answerInfo2;
    const packageId = this.$route.params.id; // 考卷ID
    const item = this.data.quizInfoList[this.idx - 1];
    const {
      quizType
    } = this.curr;
    const answers = this.answerRecord[item.id] || '';
    // 回答过了就不要重复提交了, 通过 isNext 标识直接进入下一题
    if (item.isAnswer) return Promise.resolve({
      isNext: true
    });
    if (!answers) {
      return Promise.reject(new Error(quizType === 1 ? '请选择答案' : '请填写答案'));
    }
    if (quizType === 1 && Array.isArray(answers) && answers.filter(Boolean).length === 0) {
      return Promise.reject(new Error('请选择答案'));
    }
    if (quizType === 2 && typeof answers === 'string' && answers === '') {
      return Promise.reject(new Error('请填写答案'));
    }
    let answer = answers;
    if (quizType === 1) {
      answer = answers.filter(Boolean).sort().join('');
    }
    const parms = {
      answer,
      quizId: item.id,
      packageId,
      examineId: this.examineId
    };
    let res;
    // 错题集练习时不提交每次回答。只需要在答对时将题目从错题集中剔除
    if (this.type == 1) {
      const isRight = item.answer.replace(/,/g, '') == answer.replace(/,/g, '') ? 1 : 0;
      res = {
        data: {
          answer: item.answer,
          question: answer,
          answerExplanation: item.answerExplanation,
          isRight
        },
        code: 0
      };
      if (isRight) await this.deleteOnceQuestionAction(item.id);
    } else {
      // 提交每一个回答
      res = await this.commitOnceAnswerAction(parms);
      res.data.question = answer;
    }
    this.curr.isAnswer = 1;
    item.isAnswer = 1;
    // isTest 练习模式赋值上正确答案
    if (this.isTest) {
      item.answerInfo = res.data;
      this.curr.answerInfo = res.data;
    }
    // 练习模式：回答正确延迟0.6秒后返回成功。
    if ((_item$answerInfo = item.answerInfo) !== null && _item$answerInfo !== void 0 && _item$answerInfo.isRight && this.isTest) {
      // await this.waitMinute(600) // 延迟0.6秒
      return Promise.resolve(res);
    }
    // 模拟模式: 回答直接返回成功，并且进入下一题
    else if ((_item$answerInfo2 = item.answerInfo) !== null && _item$answerInfo2 !== void 0 && _item$answerInfo2.isRight || !this.isTest || quizType !== 1) {
      return Promise.resolve({
        ...res,
        isNext: true
      });
    } else {
      return Promise.reject(new Error('回答错误'));
    }
  }
  // 等待几秒钟执行
  async waitMinute(second) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, second);
    });
  }
  // 答案点击选择事件
  onTapChooseAnswer({
    id,
    quizChoiceType,
    checked
  }, index) {
    // 提交过答案后不可重复提交
    if (this.curr.isAnswer) {
      Toast('您已经提交过答案了，不可修改');
      return;
    }
    // 获取当前问题对应的作答信息
    const answer = this.answerRecord[id] || [...new Array(checked.length)].fill('');
    const choose = this.letter[index];
    if (quizChoiceType === 1) {
      // 单选处理
      const a = answer.includes(choose) ? [] : [choose];
      this.curr.checked = checked.map((n, i) => i === index && a.includes(choose));
      this.answerRecord[id] = a;
      // // 选择完后自动跳转下一题
      if (a.length && this.isTest) {
        this.$nextTick(() => this.onTapNext());
      }
    } else {
      // 多选处理
      answer[index] = answer.includes(choose) ? '' : choose;
      // 更新选项选中状态
      this.curr.checked[index] = answer.includes(choose);
      this.answerRecord[id] = answer;
    }
  }
  onInputAnswer({
    id
  }) {
    this.answerRecord[id] = this.message;
  }
  // 反馈
  onTapFeed({
    id,
    quizContent
  }) {
    this.$router.push(`/feed/${id}?q=${encodeURIComponent(quizContent)}`);
  }
  // 验证当前试题是否全部作答完毕
  isAnswerFinish() {
    // 获取已作答完毕的题目总数
    const record = this.answerRecord;
    const ids = Object.keys(record).filter(key => {
      const item = record[key];
      if (Array.isArray(item)) {
        return item.filter(n => !!n).length > 0;
      }
      return !!item;
    });
    this.ids = ids;
    console.log(ids.length, this.lens, record);
    // 判断题目是否全部作答完成
    return ids.length === this.lens;
  }
  onSubmit() {
    // 错题集
    if (this.type == 1) {
      if (this.isPC) {
        window.location.href = `${process.env.VUE_APP_EDU_BASE_URL}person/home/0/exam?index=1`;
      } else {
        window.location.href = `${process.env.VUE_APP_EDU_BASE_URL}app/exam?showType=1`;
      }
      return;
    }
    // 验证是否所有题目都作答完毕
    if (this.isAnswerFinish()) {
      this.submitAnswer();
    } else {
      Dialog.confirm({
        title: '温馨提示',
        message: `您尚未全部完成作答，是否继续提交？`
      }).then(() => this.submitAnswer()).catch(() => {});
    }
  }
  // 提交作答结果
  async submitAnswer() {
    var _this$$refs$down, _this$$refs$down$paus;
    // 提交最后一题
    this.commitOnceAnswer();
    // 结束考次房间
    await this.endAnswerAction(this.examineId); // 考次ID
    // 暂停倒计时
    (_this$$refs$down = this.$refs.down) === null || _this$$refs$down === void 0 || (_this$$refs$down$paus = _this$$refs$down.pause) === null || _this$$refs$down$paus === void 0 || _this$$refs$down$paus.call(_this$$refs$down);
    clearInterval(myInter);
    const loading = this.showLoading();
    loading.close();
    // 判断跳转哪个结果页
    const {
      acti
    } = this.$route.query;
    if (acti) {
      window.location.href = `${process.env.VUE_APP_EDU_BASE_URL}question/result/${this.examineId}?id=${acti}`;
    } else {
      this.$router.replace(`/result/${this.examineId}`);
    }
  }
};
__decorate([Getter('Question/data')], QuestionVue.prototype, "data", void 0);
__decorate([Getter('Question/lens')], QuestionVue.prototype, "lens", void 0);
__decorate([Action('Question/getQuestionList')], QuestionVue.prototype, "getQuestionList", void 0);
__decorate([Action('Question/submitAnswerValid')], QuestionVue.prototype, "submitAnswerValid", void 0);
__decorate([Action('Question/getActivityInfoById')], QuestionVue.prototype, "getActivityInfoById", void 0);
__decorate([Action('Question/checkExaming')], QuestionVue.prototype, "checkExamingAction", void 0);
__decorate([Action('Question/starAnswer')], QuestionVue.prototype, "starAnswerAction", void 0);
__decorate([Action('Question/endAnswer')], QuestionVue.prototype, "endAnswerAction", void 0);
__decorate([Action('Question/againAnswer')], QuestionVue.prototype, "againAnswerAction", void 0);
__decorate([Action('Question/commitOnceAnswer')], QuestionVue.prototype, "commitOnceAnswerAction", void 0);
__decorate([Action('Question/getErrorRecordList')], QuestionVue.prototype, "getErrorRecordListApi", void 0);
__decorate([Action('Question/deleteOnceQuestion')], QuestionVue.prototype, "deleteOnceQuestionAction", void 0);
QuestionVue = __decorate([Options({
  components: {
    [CountDown.name]: CountDown,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Rate.name]: Rate,
    [Field.name]: Field,
    [Overlay.name]: Overlay,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog
  }
})], QuestionVue);
export default QuestionVue;